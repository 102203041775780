<template>  
    <v-dialog
            v-model="dialog"
            max-width="600px"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ teacherSelected }}
                </v-card-title>                   
                <v-card-text
                    class="pb-0"
                >                       
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="teacherFormClassRecords"
                                :items="formClassesTeacher"
                                chips
                                color="primary"
                                dense                                    
                                label="Form Teacher Classes"
                                @change="updateFormClass"
                                multiple
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="removeTeacherFormClass(data.item)"
                                    >
                                        {{ data.item }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="deanFormClassRecords"
                                :items="formClassesDean"
                                chips
                                color="primary"
                                dense                                    
                                label="Dean Form Classes"
                                @change="updateDeanFormClass"
                                multiple
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="removeDeanFormClass(data.item)"
                                    >
                                        {{ data.item }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-data-table
                            :headers="lessonHeaders"
                            :items="teacherLessons"
                            fixed-header
                            :loading="loading"
                            height="45vh"
                            :calculate-widths="true"
                            ref="LessonTable"
                            class="lesson-table"
                            :disable-pagination="true"
                        >
                            <template v-slot:item="props">
                                <TimetableLessonRow
                                    v-bind:lesson="props.item"
                                    v-bind:index="props.index"                                        
                                />
                            </template>
                        </v-data-table>
                    </v-row>    
                </v-card-text>
                <v-card-actions>
                    <span>
                        <span>{{ saveProgress }}</span>
                        <v-progress-circular
                            indeterminate
                            size="16"
                            width="3"
                            v-show="saving"
                            class="ml-2"
                        ></v-progress-circular>
                        <v-icon
                            small
                            color="green" 
                            v-show="saved"
                            class="mx-2"
                        >
                            mdi-check-all
                        </v-icon>
                        <v-icon
                            small
                            color="red"
                            v-show="saveError"
                            class="mx-2"
                        >
                            mdi-alert-circle
                        </v-icon>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-4"
                        text
                        @click="addLesson"
                        outlined
                    >
                        Add Lesson
                    </v-btn>
                    <v-btn
                        color="blue darken-4"
                        text
                        @click="closeEdit"
                        outlined
                    >
                        Close
                    </v-btn>
                </v-card-actions>
                <v-overlay
                    absolute
                    :value="overlay"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TimetableLessonRow from './EmployeesEditTimetableLessonsRecord'
export default {
    components: {
        TimetableLessonRow
    },    
    data: () => ({
        formClassesTeacher: [],
        formClassesDean: [],
        teacherFormClassRecords: [],
        deanFormClassRecords: [],
        lessonHeaders: [
            {text: 'Subj ID', value: 'subject_id', width: '40', sortable: 'false'},
            {text: 'Subject', value: 'subject_title', width: '200', sortable: 'false'},
            {text: 'Class', value: 'form_class_id', width: '90', sortable: 'false'},
            {text: '', value: 'actions', sortable: 'false', width: '30'},
            {text: '', value: 'status', sortable: 'false', width: '30'},
        ],
        loading: false,
        overlay: false,
        saving: false,
        saved: false,
        saveError: false,
        saveProgress: '',
        blankLesson: {},
        defaultLesson: {           
            subject_id: '',
            subject_title: '',
            form_class_id: '',
            employee_id: ''
        },       
    }),       
    watch: {
        editedEmployee: {
            handler () {
                this.initialize();
            }
        },
        teacherLessons: {
            handler () {
                //console.log('lessons loaded');
                this.loading = false;
                this.overlay = false;
            }
        }        
    },    
    computed: {
        ...mapGetters({            
            teacherLessons: 'timetable/getTeacherLessons',           
            academicYearId: 'app/getAcademicYearId',            
            dialog: 'timetable/getDialog',
            editedEmployee: 'timetable/getEditedEmployee',
            formClasses: 'timetable/getFormClasses',           
        }),
        teacherSelected(){
            return this.editedEmployee.first_name + ' ' + this.editedEmployee.last_name;
        },                   
    },
    methods: {
        ...mapActions({
            getTeacherLessons: 'timetable/getTeacherLessons',
            postTeacherLesson: 'timetable/postTeacherLesson',            
            getFormTeacherClass: 'timetable/getFormTeacherClass',
            postFormTeacherAssignment: 'timetable/postFormTeacherAssignment',
            getFormDeanAssignments: 'timetable/getFormDeanAssignments',
            postFormDeanAssignments: 'timetable/postFormDeanAssignments',          
                     
        }),
        ...mapMutations({           
            setTeacherLessons: 'timetable/setTeacherLessons', 
            setFormTeacherAssignment: 'timetable/setFormTeacherAssignment',
            setFormDeanAssignments: 'timetable/setFormDeanAssignments',           
            setEditedEmployee: 'timetable/setEditedEmployee',           
            setDialog: 'timetable/setDialog'            
        }),
        async initialize () {
            //console.log('component initialized');
            this.loading = true;
            this.overlay = true;
            this.blankLesson = {...this.defaultLesson};                        
            this.blankLesson.employee_id = this.editedEmployee.id;
            try {                 
                this.formClassesTeacher = [...this.formClasses];
                this.formClassesDean = [...this.formClasses];
                
                const teacherLessonsPromise = this.getTeacherLessons();
                const teacherFormClassPromise = this.getFormTeacherClass(this.academicYearId);
                const deanFormClassPromise = this.getFormDeanAssignments();
                
                let response = await teacherLessonsPromise;
                //console.log(response);
                this.setTeacherLessons(response.data);                
                              
                response = await teacherFormClassPromise;                
                if(response.data.length > 0){                    
                    response.data.forEach(record => {
                        this.teacherFormClassRecords.push(record.form_class_id);
                    })                    
                }
                else{                    
                    this.teacherFormClassRecords = [];
                }
                
                response = await deanFormClassPromise;
                if(response.data.length > 0){
                    response.data.forEach(record => {
                        this.deanFormClassRecords.push(record.form_class_id);
                    })
                }
                else{                   
                    this.deanFormClassRecords = [];
                } 

            } catch (error) {
                this.overlay = false;
                this.loading = false;
                console.log(error);
            }            
        },
       
        closeEdit(){
            console.log('closing...');
            this.saved = false;
            this.saving = false;
            this.saveError = false;
            this.saveProgress = '';   
            this.setTeacherLessons([]);
            this.teacherFormClassRecords = [];
            this.deanFormClassRecords = [];
            this.setEditedEmployee({});
            this.setDialog(false);
        },
             
        addLesson(){
            console.log('add lesson ..');           
            //this.loading = true;            
            let selectedTeacherLessons = [...this.teacherLessons];            
            selectedTeacherLessons.unshift(Object.assign({}, this.blankLesson));
            //console.log(selectedTeacherLessons);
            this.setTeacherLessons([]);

            this.$nextTick(() => {
                this.setTeacherLessons(selectedTeacherLessons);
            })                 
                                                                  
                    
        },
        async updateFormClass(){
            console.log('updating form class...'); 
            this.saved = false;
            this.saveError = false;
            this.saveProgress = 'Saving ';
            this.saving = true;           
            
            let formClassAssignment = {                
                form_class_ids: this.teacherFormClassRecords,
                employee_id: this.editedEmployee.id,                
            }
            this.setFormTeacherAssignment(formClassAssignment);
            try {
                let response = await this.postFormTeacherAssignment();
                console.log(response);
                //this.formClassRecord.id = response.data.id;
                this.saving = false;
                this.saveError = false;
                this.saveProgress = 'Saved ';
                this.saved = true;
                //console.log(response);
            } catch (error) {
                console.log(error);
                this.saving = false;
                this.saved = false;
                this.saveProgress = 'Error Occured';
                this.saveError = true;
            }
        },
        async updateDeanFormClass(){
            this.saved = false;
            this.saveError = false;
            this.saveProgress = 'Saving ';
            this.saving = true;
            
            let deanClassAssignment = {
                form_classes: this.deanFormClassRecords,
                employee_id: this.editedEmployee.id,
            }
            this.setFormDeanAssignments(deanClassAssignment);
            try {
                let response = await this.postFormDeanAssignments();
                console.log(response);
                this.saving = false;
                this.saveError = false;
                this.saveProgress = 'Saved ';
                this.saved = true;
            } catch (error) {
                console.log(error);
                this.saving = false;
                this.saved = false;
                this.saveProgress = 'Error Occured';
                this.saveError = true;
            }

        },
        removeTeacherFormClass (item) {
            const index = this.teacherFormClassRecords.indexOf(item);
            if(index >= 0) this.teacherFormClassRecords.splice(index, 1);
            this.updateFormClass();
        },
        removeDeanFormClass (item) {
            const index = this.deanFormClassRecords.indexOf(item);
            if(index >= 0) this.deanFormClassRecords.splice(index, 1);
            this.updateDeanFormClass();
        },
    }
}
</script>