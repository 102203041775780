<template>
    <v-app>
        <v-app-bar 
            app
            :color="themeColor"
            dark
            clipped-left
            elevation="0"
        >
            <div class="d-flex align-center white--text">
                <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="40"
                />
                {{ schoolName}}
                
            </div>
            <v-spacer></v-spacer>
            <Logout/>
        </v-app-bar>

        <v-navigation-drawer
            :mini-variant.sync="mini_md"            
            app
            clipped
            :color="themeColor"
            dark
            permanent
            width="200"
            class="d-none d-md-block"
        >         
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Term Reports
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Administrator
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <AdminNavigation/>            
        </v-navigation-drawer>       

        <v-main class="grey lighten-5">
            <AdminDashboard v-if="dashboard"/>
        </v-main>

        <v-overlay :value="progressOverlay">
            <v-progress-circular
                indeterminate
                size="64"
                :color="themeColor"
            ></v-progress-circular>
        </v-overlay>

        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="modalClose"
        >
            {{ modalMessage }}
        </sweet-modal>
        
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import AdminNavigation from './AdminNavigation';
import AdminDashboard from './AdminDashboard';
import { SweetModal } from 'sweet-modal-vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Admin',
    components: {
        Logout,
        AdminDashboard,        
        AdminNavigation,        
        SweetModal,
    },
    
    computed: {      
      themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
      },
      avatarColor(){
          return this.avatarColor + ' ' + this.avatarDarken;
      },      
        ...mapGetters({
            getUser: 'termReports/getUser',
            schoolName: 'termReports/getSchoolName',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',        
            dashboard: 'admin/getDashboard',        
            registerStudents: 'admin/getRegisterStudents',
            modal: 'admin/getModal',
            modalTitle: 'admin/getModalTitle',
            modalIcon: 'admin/getModalIcon',
            modalMessage: 'admin/getModalMessage',
            progressOverlay: 'admin/getProgressOverlay',                  
        }),           
    },
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        }
    },
    data: () => ({                
        dialog: false,
        mini_md: false,        
    }),
    methods: {
        ...mapMutations({
            setModal: 'admin/setModal',
        }),
        openModal(){            
            this.$refs.modal.open();
        },
        modalClose(){
            this.setModal(false);
        }        
    }
}
</script>