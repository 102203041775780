<template>
    <div style="position:relative; height:380px; ">
        <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :width="width"
            :height="height"
            :plugins="plugins"
        ></Bar>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    components: { Bar },
    props: {
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 60
        }
    },
    data () {
        return {
            chartData: {
                labels: ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5', 'Form 6'],
                datasets: [
                    {
                        label: 'Present',
                        data: [50, 34, 56, 45, 56, 78],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 205, 86, 0.2)',
                        ]
                    },
                    {
                        label: 'Absent',
                        data: [10, 24, 5, 15, 6, 17],
                        backgroundColor: 'rgba(190, 190, 190, 0.2)',                           
                    },
                ]
            },
            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        stacked: true,
                    },
                    x: {
                        stacked: true,
                        grid:{
                            display: false
                        }
                    }
                },
                animation: {
                    // onComplete: function({ chart }) {
                    //     // console.log(animation);
                    //     console.log(chart);
                    //     // var height = chart.height,
                    //     // var ctx = chart.ctx;
                    //     // chart.data.datasets.forEach(function (dataset) {
                    //     //     console.log(dataset._meta);
                    //     //     // for(var i = 0; i < dataset.data.length; i++){
                    //     //     //     var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                    //     //     //     ctx.fillText(dataset.data[i], model.x, model.y - 5);
                    //     //     // }
                    //     // })
                    //     // // ctx.font = ctx.font;
                    //     // // ctx.fillStyle = chart.scale.textColor;
                    //     // ctx.textAlign = "center";
                    //     // ctx.textBaseline = "bottom"

                    //     // chart.config.data.datasets.forEach(function (dataset) {
                    //     //     console.log(dataset);
                    //     // })
                    // }
                }
                
            },
            plugins: [
                {
                    afterDraw: function (chartInstance) {
                        console.log(chartInstance);
                        var ctx = chartInstance.ctx;
                        chartInstance.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.getDatasetMeta(i);
                            if(!meta.hidden){
                                meta.data.forEach(function(element, index){
                                    ctx.fillStyle = 'rgb(0,0,0)';
                                    var fontSize = 16;
                                    var dataString = dataset.data[index].toString();

                                    ctx.textAlign = 'center';
                                    ctx.textBaseLine = 'middle';

                                    var padding = 15;
                                    var position = element.tooltipPosition();
                                    ctx.fillText(dataString, position.x, position.y+(fontSize/2) - padding)
                                })
                            }
                        })
                    }
                }
            ]
        }
    }
}
</script>