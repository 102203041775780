<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            <v-card-title
                :class="primary"                
            >
                Select Subject Class
            </v-card-title>
            
            <v-card-text
                style="height: 60vh; overflow-y: scroll;"
                class="px-0"
            >
                <v-list 
                    light              
                >
                    <v-list-item-group v-model="lessonSelected">
                        <v-list-item
                            v-for="(item, i) in teacherLessons"
                            :key="i"
                            @click="setLesson(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon :class="avatarClass">
                                    mdi-account-group
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.lesson"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>    
        </v-card>
    </v-expand-transition>         
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {    
    created: function(){        
        this.initialize();
    },    
    data: () => ({        
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        open: true,
        clear: true,       
        focus: false,
        records: [],        
    }),    
    computed: {
        ...mapGetters({            
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',            
            academicTermId: 'app/getAcademicTermId',
            id: 'auth/getEmployeeId',
            teacherLessons: 'markSheet/getTeacherLessons',
            expand: 'markSheet/getExpandLessons',                                                            
        }),
        primary(){
            return 'primary white--text';
        },
        avatarClass(){
            return  'primary white--text';
        } 
    },
    methods: {
        ...mapActions({
           getLessonRecords: 'markSheet/getLessons',
           getStudents: 'markSheet/getStudents',
           getPresetCommentsList: 'markSheet/getPresetCommentsList',            
        }),
        ...mapMutations({
            setLessonSelected: 'markSheet/setLessonSelected',
            setExpand: 'markSheet/setExpandLessons',
            markSheetLoading: 'markSheet/setMarkSheetLoading',
            markSheetOverlay: 'markSheet/setMarkSheetOverlay',
            setTeacherLessons: 'markSheet/setTeacherLessons',
            setTermMarkRecords: 'markSheet/setTermMarkRecords',
            setTotalStudents: 'markSheet/setTotalStudents',
            setPresetComments: 'markSheet/setPresetComments',
            setModal: 'app/setModal',
            setModalTitle: 'app/setModalTitle',
            setModalIcon: 'app/setModalIcon',
            setModalMessage: 'app/setModalMessage',            
        }),
        async setLesson(lesson){ 
            //console.log(lesson);                                                    
            this.setLessonSelected(lesson);
            this.setExpand(false);
            this.setTermMarkRecords([]);
            setTimeout(() => {
               this.markSheetLoading(true); 
            }, 700);
            try {
                console.log(this.id);
                let response = await this.getStudents({academicTermId: this.academicTermId, employeeId: this.id});
                //console.log(response);
                this.updateTermMarkRecords(response.data.data);
                //console.log(response.data.data);
                this.setTotalStudents(response.data.total);

                response = await this.getPresetCommentsList();
                this.setPresetComments(response.data);                
                this.markSheetLoading(false);
                this.markSheetOverlay(false);

            } catch (error) {
                console.log(error);
                this.markSheetLoading(false);
                this.markSheetOverlay(false);
                this.displayError(error);
            }
                                  
        },
        updateTermMarkRecords(records){
            let termMarkRecords = [];
            let count = 0;
            records.forEach(record =>{
                let termMarkRecord = {};
                termMarkRecord.count = ++count;
                termMarkRecord.student_id = record.student_id;
                termMarkRecord.name = record.first_name + ' ' + record.last_name;
                termMarkRecord.subject_id = record.subject_id;
                termMarkRecord.course_mark = record.course_mark;
                termMarkRecord.exam_mark = record.exam_mark;
                termMarkRecord.conduct = record.conduct;
                // termMarkRecord.interest = record.interest;
                termMarkRecord.employee_id = record.employee_id;
                termMarkRecord.academic_term_id = record.academic_term_id;
                termMarkRecord.comment = record.comment;
                termMarkRecord.course_attendance = record.course_attendance;
                termMarkRecord.exam_attendance = record.exam_attendance;
                termMarkRecords.push(termMarkRecord);
            })
             this.setTermMarkRecords(termMarkRecords);
        },
        async initialize(){            
            try {
                let response = await this.getLessonRecords(this.id);
                this.setTeacherLessons([]);                
                this.markSheetLoading(false);
                this.setExpand(true); 
                if(response.data.length > 0){
                    response.data.forEach(lessonRecord =>{
                        let subject = lessonRecord.subject_title;
                        this.teacherLessons.push({
                            lesson: lessonRecord.form_class_id + ' ' + subject,
                            className: lessonRecord.form_class_id,                    
                            subjectCode: lessonRecord.subject_id,
                            subjectTitle: subject,
                            formLevel: lessonRecord.form_level,
                        })
                        this.focus = true;
                    });
                    this.setTeacherLessons(this.teacherLessons);
                }
                else{
                    this.setExpand(true);
                    this.setModalIcon('warning');
                    this.setModalMessage('No Classes have been assigned');
                    this.setModal(true);
                }                
            } catch (error) {
                console.log(error);
                this.markSheetLoading(false);
                this.displayError(error);
            }       
        },
        displayError(error){
            this.setModalIcon('error');
            this.setModalMessage(error);
            this.setModal(true);
        }


    }
}
</script>