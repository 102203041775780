import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/auth/Login'
import AdminLogin from '../components/auth/AdminLogin'
import Admin from '../components/Admin'
import App from '../components/App'
//import App from '../App'
import store from '../store' 

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresVisitor: true,
    }
  },
  {
    path: '/app',
    name: 'App',
    component: App,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminLogin,
    meta: {
      requiresVisitor: true,
    }
  },
  { 
    path: '/admin/dashboard',
    name: 'AdminDashboard', 
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //console.log("Authenticated: ", store.getters['auth/authenticated']);
    if (!store.getters['auth/getAuthenticated']) {
      next({
        path: '/',
        
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //console.log("Authenticated: ", store.getters['auth/authenticated']);
    if (store.getters['auth/getAuthenticated']) {
      next({
        path: '/term-reports',
        
      })
    } else {
      next()
    }
  }else {
    next() // make sure to always call next()!
  }
})

export default router
