<template>
    <v-container class="pt-0 pb-0">
        <v-row
            align='center'
        >
            <v-col cols="4">
                <v-card 
                    tile
                    width="230"
                    class="py-0"
                    flat
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0"
                        two-line
                    >
                        <v-list-item-group color="primary">
                            
                            <v-list-item>                                
                                <v-list-item-content
                                    class="pa-0 mb-3"
                                >
                                    <v-list-item-title>
                                        <span class="pr-1">Subject:</span><span class="font-weight-bold">{{ lessonSelected.subjectTitle }}</span>                                    
                                    </v-list-item-title>                                    
                                </v-list-item-content> 
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>                    
                    
                </v-card>                
            </v-col>
            <v-col
                class="d-flex align-end mb-2"
                cols="5"
            >
                <div class="d-flex pa-1">
                    <v-text-field
                        label="Class"
                        class="mr-1 caption centered-input small-input"
                        dense
                        outlined                        
                        v-model="lessonSelected.className"
                        hide-details
                        disabled
                    ></v-text-field>
                    <v-text-field
                        label="Year"
                        class="mr-1 caption centered-input"
                        dense
                        outlined
                        v-model="academicYear"
                        hide-details
                        disabled
                    ></v-text-field>
                    <v-text-field
                        label="Term"
                        v-model="term"
                        class="mr-1 caption centered-input small-input term-name"
                        dense
                        outlined
                        hide-details
                        disabled
                    ></v-text-field>
                </div>
            </v-col>                         
            <v-col
                cols="3"
            >
                <v-btn  
                    :color="themeColor"
                    dark
                    @click="nextSubjectClass"
                >Next Subject Class</v-btn>
            </v-col>          
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    watch: {
        studentSelected: {
            handler: function (val) {
                //console.log('marksheetheader:', val);
                this.studentName = val.studentName;
                if(val.picture === null){
                    this.imageSrc = null;
                }
                else{
                    this.imageSrc = "/img/students/" + val.picture;                    
                }
            },
            deep: true,
        },
        
        studentsRegistered: {
            handler: function (val) {
                this.classSummary[1].value = val;
            }
        },
        studentsMarksEntered: {
            handler: function (val) {
                this.classSummary[2].value = val;
            }
        }
    },
    computed: {
        ...mapGetters({
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',
            academicYear: 'app/getAcademicYear',
            term: 'app/getTerm',
            lessonSelected: 'markSheet/getLessonSelected',
            studentsTotal: 'markSheet/getTotalStudents',            
            
        }),        
        themeColor(){
            return 'primary';
        },        
    },
    data(){
        return {                     
            nameLabel: "Name",
            imageSrc: '',
            studentName: '',          
            
        }
    },
    methods: {
        ...mapMutations({
            setOverlay: 'markSheet/setMarkSheetOverlay',
            expand: 'markSheet/setExpandLessons',            
            termMarkRecords: 'markSheet/setTermMarkRecords',            
            //setStudentName: 'termReports/setMarkSheetStudentSelected',
        }),
        nextSubjectClass(){
            this.termMarkRecords([]);
            this.setOverlay(true);
            //this.setStudentName('');
            setTimeout(() => {
                this.expand(true);
            }, 600)
            
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input{
        text-align: center;
    }
    .small-input >>> input{
        width: 35px;;
    }
    .v-list-item{
        min-height: 0;
    } 
    .term-name >>> input{
        width: 100px !important
    }  
</style>