<template>
    <v-list
        dense
        nav
    >
        <v-list-item-group
            v-model="selectedItem"
            color="white"
        > 
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :class="itemClass(item)"                        
                @click="display(item)"                       
            >
                <v-list-group
                    v-if="item.submenu && item.visible"
                    :prepend-icon="item.icon"
                    no-action
                                                
                >
                    <template v-slot:activator>                                
                        <v-list-item-title
                            v-text="item.text"
                        ></v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="(item, i) in reports"
                        :key="i"
                        @click="display(item)"
                        link
                    >
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item-icon v-if="!item.submenu && item.visible">
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="!item.submenu && item.visible">
                    <v-list-item-title 
                        v-text="item.text">
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>

        </v-list-item-group>                
    </v-list>                 
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    name: 'AdminNavigation',
    data: () => ({
        reports: [
            {text: 'Parents Contact', menu: 'parents-contact'}
        ],
        itemClass(item){          
            //console.log(item)            
            if(item.submenu && item.visible) return "pa-0"
            else if(item.visible) return ""
            else return "d-none pa-0"
                      
        },
        items: [
            { text: 'Dashboard', icon: 'mdi-view-dashboard', submenu: false, menu: 'dashboard', visible: true },            
            //{ text: 'Register Students', icon: 'mdi-account-group', submenu: false, menu: 'register', visible: true },            
            //{ text: 'Export Data', icon: 'mdi-database-export-outline', submenu: false, menu: 'exportData', visible: true },
            //{ text: 'Reports', icon: 'mdi-file-pdf', submenu: true, menu: 'reports', visible: true},
            //{ text: 'Import Students', icon: 'mdi-cloud-upload-outline', submenu: false, menu: 'importData',visible: false },
        ],
        visibleMenu:{
            dashboard: true,
            registerStudents: true,            
            reports: false,
            importData: false,
            //parentsContact: false,
        },
        selectedItem: 0,       
    }),
    watch: {
        'visibleMenu.dashboard' : (val) => {
            console.log(val)
            //this.setDashboard(val)
        },
        'visibleMenu.registerStudents' : (val) => {
            console.log(val)
        },
        'visbleMenu.reports' : (val) => {
            console.log(val)
            //this.setReports(val)
        },
        'visibleMenu.importData' : (val) => {
            console.log(val)
            //this.setImportData(val)
        }
    },
    methods: {
        ...mapActions({
            registrationData: 'admin/downloadRegistrationData'
        }),
        ...mapMutations({
            setDashboard: 'admin/setDashboard',
            setExportData: 'admin/setExportData',
            setReports: 'admin/setReports',
            setImportStudents: 'admin/setImportStudents',
            setRegister: 'admin/setRegister',
        }),
        display(item){
            console.log(item.menu);
            let menu = item.menu;            
            //console.log(menu);
            switch(menu){
                case 'dashboard':
                    this.setDashboard(true);
                    this.setExportData(false);
                    this.setReports(false);                    
                    break;
                case 'register':
                    this.setDashboard(false);
                    this.setRegister(true);
                    break;    
                case 'exportData':
                    //this.setDashboard(true);
                    this.setExportData(true);
                    //this.setReports(false);
                    this.downloadExcel();                    
                    break;
                case 'reports':
                    this.setDashboard(false);
                    this.setExportData(false);
                    this.setReports(true);                    
                    break;
            }
        //     for(const [key, value] of Object.entries(this.visibleMenu)){
        //         console.log(`key: ${key} value: ${value}`);
        //         if(menu != 'exportData'){
        //             if(key == menu) {
        //                 this.visibleMenu[key] = true;
        //                 if(menu == 'dashboard') this.setDashboard(true);
        //                 else if( menu == 'reports') this.setReports(true);
        //             }
        //             else this.visibleMenu[key] = false;
        //         }
        //         else{
        //             this.downloadExcel();
        //             break;
        //         } 
        //    }           
        },
        downloadExcel(){
            this.registrationData()
            .then(response => {                
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download','Registration Data.xlsx')
                document.body.appendChild(link)                
                link.click()
                this.setExportData(false);                
            })
        }, 
    }
}
</script>