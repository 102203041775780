<template>
    <v-row
        justify="center"
    >
        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="1000"
                height="80vh"
            >
                <v-card-title
                    :class="titleClass"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-bold primary--text text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        :color="themeColor"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkTable/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="markSheetOverlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/> 
                        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="markSheetLoading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
        
    </v-row>    
</template>

<script>
import Header from './MarkSheetHeader'
import Lesson from './MarkSheetLesson'
import MarkTable from './MarkSheetTable'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'MarkSheet',
    created(){
        this.initialize();
    },
    components: {
        Header,
        Lesson,
        MarkTable,
    },
    computed: {
        ...mapGetters({            
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',                      
            markSheetOverlay: 'markSheet/getMarkSheetOverlay',
            markSheetLoading: 'markSheet/getMarkSheetLoading',

            totalStudents: 'markSheet/getTotalStudents',  
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
        titleClass(){           
            return 'd-flex ' + this.primaryColor +'--text text--' + this.colorDarken + ' py-2 px-0 '
        },
        buttonColor(){
            return this.primaryColor + ' ' + this.colorDarken;
        }
    },
    data: () => ({
        absolute: true,             
        userTimetable: [],
    }),
    methods: {
        ...mapMutations({
            mainMenu: 'dashboard/setMainMenu',           
            overlay: 'markSheet/setMarkSheetOverlay',
            expand: 'markSheet/setExpandLessons',
            welcomeMessage: 'dashboard/setWelcomeMessage',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading',
            
        }),
        initialize(){
            this.overlay(true);
            this.expand(false);
            this.setMarkSheetLoading(true);            
        },              
        closeMarkSheet(){
            this.overlay(true);
            this.setMarkSheetLoading(true);           
            this.expand(false);
            
        }
    }
}
</script>