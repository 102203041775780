import axios from 'axios'

export default {
    namespaced: true,

    state: {
        dashboard: true,
        exportData: false,
        reports: false,
        importStudents: false,
        registerStudents: false,
        modal: false,
        modalIcon: '',
        modalTitle: '',
        modalMessage: '',
        progressOverlay: false,        
    },

    getters: {
        getDashboard(state){
            return state.dashboard
        },
        getExportData(state){
            return state.exportData
        },
        getReports(state){
            return state.reports
        },
        getImportStudents(state){
            return state.importStudents
        },
        getRegisterStudents(state){
            return state.registerStudents
        },
        getModal(state){
            return state.modal
        },
        getModalIcon(state){
            return state.modalIcon
        },
        getModalTitle(state){
            return state.modalTitle
        },
        getModalMessage(state){
            return state.modalMessage
        },
        getProgressOverlay(state){
            return state.progressOverlay
        }
    },

    mutations: {
        setDashboard(state, value){
            state.dashboard = value
        },
        setExportData(state, value){
            state.exportData = value;
        },
        setReports(state, value){
            state.reports = value
        },
        setImportStudents(state, value){
            state.importStudents = value
        },
        setRegisterStudents(state, value){
            state.registerStudents = value
        },
        setModal(state, value){
            state.modal = value
        },
        setModalTitle(state, value){
            state.modalTitle = value
        },
        setModalIcon(state, value){
            state.modalIcon = value
        },
        setModalMessage(state, value){
            state.modalMessage = value
        },
        setProgressOverlay(state, value){
            state.progressOverlay = value
        }
    },

    actions: {
        async downloadRegistrationData(){
            return axios({
                method: 'get',
                url: '/api/registration-data-spreadsheet',
                responseType: 'arraybuffer',
            });
        },

        async resetPassword(state, id){
            let url = '/api/reset-password'
            return axios.post(url, {
                "student_id" : id
            });            
        },

        registerAll(){
            let url = '/api/term-reports-register'            
            return axios.post(url);
        }
    }
}    