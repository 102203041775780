<template>
    <v-container fluid>
        <v-card
            width="300"
            height="500"
            class="mx-auto"
            elevation="2"
            outlined
        >
            <v-card-title
                :class="cardTitleClass"
            >
                Set Current Term
            </v-card-title>
            <v-card-text
                class="pt-6"
            >
                <v-select
                    label="Academic Year"                    
                    :items="academicYear"
                    text="text"
                    value="value"
                    v-model="selectedYear"
                ></v-select>
                <v-select
                    label="Term"                    
                    :items="terms"
                    text="text"
                    value="value"
                    v-model="selectedTerm"
                ></v-select>
                <v-text-field
                    label="Possible Attendance"
                    v-model="possibleAttendance"
                    type="number"
                    class="mb-4"
                ></v-text-field>                
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"                   
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="New Term Beginning"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    ref="picker"
                    v-model="date"
                    
                    min="1950-01-01"
                    @change="save"
                    ></v-date-picker>
                </v-menu>
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :color="themeColor"
                    dark
                    class="mr-8"
                    @click="registerAll"
                    text                 
                >
                    Register All
                </v-btn>                
                <v-btn
                    :color="themeColor"
                    dark
                    outlined                    
                >
                    Set Term
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'AdminDashboard',    
    data: () => ({
        academicYear: [
            {text: '2020-2021', value: '2020'},
            {text: '2021-2022', value: '2021'},
        ],
        terms: [
            {text: 'Michaelmas', value: 1},
            {text: 'Hilary', value: 2},
            {text: 'Trinity', value: 3}           
        ],
        selectedYear: '',
        selectedTerm: '',
        possibleAttendance: '',
        newTermBeginning: '',
        date: null,
        menu: false,   
    }),
    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },
    computed: {      
      themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
      },            
        ...mapGetters({        
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
      }),
      cardTitleClass(){
          return 'white--text ' + this.themeColor;
      }      
    },  
    methods: {
        ...mapMutations({
            setModal: 'admin/setModal',
            setModalTitle: 'admin/setModalTitle',
            setModalMessage: 'admin/setModalMessage',
            setModalIcon: 'admin/setModalIcon',
            setOverlay: 'admin/setProgressOverlay'
        }),      
        ...mapActions({            
            register: 'admin/registerAll'
        }),       
        async registerAll(){            
            console.log(this.selectedYear);
            console.log(this.selectedTerm);
            console.log(this.date);
            if(this.selectedYear && this.selectedTerm){
                this.setOverlay(true);
                try {
                    let response = await this.register();
                    console.log(response);
                    this.setOverlay(false);
                    if(response.status === 200){
                        this.setModal(true);
                        this.setModalTitle('');
                        this.setModalMessage('Students Registered Successfully.')
                        this.setModalIcon('success')
                    }
                    else{
                        this.setModal(true);
                        this.setModalTitle('Error');
                        this.setModalMessage('An Error has Occurred. Please contact Admin (Error Status not 200)');
                        this.setModalIcon('error');
                    }
                    
                } catch (error) {
                    this.setOverlay(false);
                    console.log(error);
                    this.setModal(true);
                    this.setModalTitle('Error');
                    this.setModalMessage(error);
                    this.setModalIcon('error');
                }
            }
            else{
                this.setModal(true);
                this.setModalTitle('Error');
                this.setModalMessage('Please set the Academic Year and Term to continue.');
                this.setModalIcon('error');
            }
            
            
        },
        save(date){
            this.$refs.menu.save(date)
        }
    }
}
</script>