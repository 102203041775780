<template>
    <tr>
        <td>{{ lessonRecord.subject_id }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonRecord.new_subject_id"
                :items="subjects"
                item-text="title"
                item-value="id"                
                outlined
                dense
                hide-details
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="lessonRecord.new_form_class_id"
                :items="formClasses"
                outlined
                dense
                hide-details
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-show="saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-show="saved"
                class="mr-2"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-show="saveError"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        index: Number,        
    },
    created () {
        //console.log('lesson created');
        this.initialize();
    },
    data: () => ({
        subjects: [],
        formClasses: [],
        lessonRecord: {},
        saving: false,
        saved: false,
        saveError: false,       
             
    }),
    computed: {
        ...mapGetters({
            academicYearId : 'app/getAcademicYearId',
            teacherLessons :  'timetable/getTeacherLessons',
            lessonSubjects: 'timetable/getSubjects',
            lessonClasses: 'timetable/getFormClasses',
            editedEmployee: 'timetable/getEditedEmployee',            
        }),
    },
    watch: {
        lesson: {
            handler(){
                //console.log('record changed');
                //console.log(val);
                //this.initialize();
                // this.lessonSubject.id = val.subjectId;
                // this.lessonSubject.title = val.subject;                
            }
        },
        subject:{
            handler (val) {
                console.log(val);
                this.lessonRecord.subject_id = val;
                console.log(this.lesson);                
            }
        }
    },
    methods: {
        async initialize(){
            //console.log('record initialized');
            //console.log(this.lesson);
            this.subjects = [...this.lessonSubjects];
            //console.log(this.index);
            //console.log(this.subjects);
            this.formClasses = [...this.lessonClasses];            
            this.lessonRecord = {...this.lesson};            

            this.lessonRecord.new_subject_id = this.lesson.subject_id;            
            this.lessonRecord.new_form_class_id = this.lesson.form_class_id;           
                      
            //console.log(this.lesson);            
        },
        ...mapMutations({
            setPostLesson: 'timetable/setPostLesson',
            setDeleteLesson: 'timetable/setDeleteLesson',
            setTeacherLessons: 'timetable/setTeacherLessons',            
        }),
        ...mapActions({
            postLesson: 'timetable/postLesson',
            deleteLesson: 'timetable/deleteLesson',
            displayModal: 'app/displayModal',
            getTeacherLessons: 'timetable/getTeacherLessons',            
        }),
        async updateLesson(){
            console.log('updating lesson...');
            
            //console.log(this.lessonRecord);
            //console.log(this.postLessonRecord);
            if(this.lessonRecord.new_form_class_id && this.lessonRecord.new_subject_id){
                //this.submitLesson();
                console.log('lesson not blank');
                this.saved = false;
                this.saveError = false; 
                this.saving = true;
                this.setPostLesson(this.lessonRecord);
                try {
                    let response = await this.postLesson();
                    if(response.status == 200){
                        this.saving = false;
                        this.saved = true;
                        this.saveError = false;
                        let teacherLessonsCopy = [...this.teacherLessons];
                        teacherLessonsCopy[this.index].form_class_id = this.lessonRecord.new_form_class_id;
                        teacherLessonsCopy[this.index].subject_id = this.lessonRecord.new_subject_id;
                        //this.setTeacherLessons([]);
                        console.log(teacherLessonsCopy);
                        this.$nextTick(() => {
                            this.setTeacherLessons(teacherLessonsCopy)
                        });
                        // console.log(this.teacherLessons);                    
                    }
                    else{
                        this.saving = false;
                        this.saved = false;
                        this.saveError = true;
                    }
                } catch (error) {
                    console.log(error.response);
                    let payload = {
                        response: error.response,
                        icon: 'error',                    
                    }
                    this.displayModal(payload);
                    this.saving = false;
                    this.saved = false;
                    this.saveError = true;
                }                 
            }
            else{
                console.log('lesson blank');               
                
            }            
        },
        
        async deleteSelectedLesson(){            
            if(this.lesson.subject_id && this.lesson.form_class_id){
                this.saved = false;
                this.saveError = false;
                this.saving = true;           
                
                this.setDeleteLesson(this.lesson);                              
                
                try {
                    let response = await this.deleteLesson();
                    console.log(response);                
                    this.saving = false;
                    this.saved = false;
                    this.saveError = false;

                    let teacherLessonsReduced = this.teacherLessons.filter( (lesson, index) => {                        
                        return index !== this.index;
                    })

                    //console.log(teacherLessonsReduced);
                   
                    // response = await this.getTeacherLessons(this.employee_id);
                    // console.log(response);                
                    // let records = response.data;
                    
                    // //let teacherLessonsCopy = [...this.teacherLessons];
                    this.setTeacherLessons([]);
                    this.$nextTick(() => {
                        this.setTeacherLessons(teacherLessonsReduced);
                    })                    

                } catch (error) {                   
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.saved = false;
                    this.saveError = true;
                }
            }
            else{
               //remove blank lesson
               let teacherLessonsReduced = this.teacherLessons.filter( (lesson, index) => {                    
                    return index !== this.index;
                })

                this.setTeacherLessons([]);
                this.$nextTick(() => {
                    this.setTeacherLessons(teacherLessonsReduced);
                })
            }
            
        }
    }
}
</script>