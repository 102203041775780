<template>
    <v-dialog
        :value="dialog"
        max-width="960px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                @click="dialog=true"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
            >
                View Attendance
            </v-btn>                            
        </template>

        <v-card>
            <v-card-title class="text-center justify-center py-6">
                <h1 class="font-weight-bold text-h4">
                    Student Attendance
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="red"
                    outlined
                    @click="dialog=false"
                >                
                    Close
                    <v-icon
                        right
                    >mdi-window-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col cols="4">
                        <v-tabs 
                            v-model="tab2" 
                            grow 
                            hide-slider
                        >
                            <v-tab></v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab2">
                            <v-tab-item>
                                <v-row justify="center">
                                        <v-date-picker
                                            v-model="picker"
                                            elevation="2"
                                            width="260"
                                            class="mt-3"
                                        ></v-date-picker>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                    <v-col cols="8">
                        <v-tabs
                            v-model="tab"
                            grow
                        >
                            <v-tab>Summary</v-tab>
                            <v-tab>Form Levels</v-tab>
                            <v-tab>Students</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-row>
                                    <v-col cols="6">
                                        <v-card >
                                            <v-card-title class="text-center justify-center py-6">Current Population</v-card-title>
                                            <ChartDoughnut
                                                :chart-data="attendanceData"
                                                :chart-options="attendanceChartOptions"
                                            ></ChartDoughnut>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-card-title class="text-center justify-center py-6">Late Population</v-card-title>
                                            <ChartDoughnut
                                                :chart-data="lateData"
                                                :chart-options="lateChartOptions"
                                            ></ChartDoughnut>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col>
                                        <v-card class="px-4">
                                            <ChartBar></ChartBar>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col>
                                        
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>                   
                </v-row>
            </v-container>
        </v-card>
                
    </v-dialog>    
</template>

<script>
import ChartDoughnut from './ChartDoughnut.vue';
import ChartBar from './ChartBar.vue';


export default {
    components: {
        ChartDoughnut,
        ChartBar,
    },

    data: () => ({
        dialog: false,
        
        attendanceData: {
            labels: ['Present', 'Absent'],
            datasets: [{
                label: 'Current Attendance',
                data: [300, 50],
                backgroundColor: [
                    'rgb(54, 162, 235)',
                    'rgb(220, 220, 220)',
                ],
                borderJoinStyle: 'round',
                borderRadius: 0,
                hoverOffset: 4,
                borderWidth: 0,
                
            }]
        },
        lateData: {
            labels: ['Late', 'Early'],
            datasets: [{
                label: 'Current Attendance',
                data: [100, 300],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(220, 220, 220)',
                ],
                borderJoinStyle: 'round',
                borderRadius: 0,
                hoverOffset: 4,
                borderWidth: 0,
                
            }]
        },
        attendanceChartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },
            cutout: '80%',
            text: '93%',
        },
        lateChartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },
            cutout: '80%',
            text: '39%',
        },
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        tab: null,
        tab2: 0,
    }),

    close () {
        this.dialog = false;
    }
}
</script>