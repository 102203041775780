//import axios from 'axios'
export default {
    namespaced: true,
    state: {
        welcomeMessage: true,
        mainMenu: true,
        markSheet: false,
        termReports: false,
        reports: false,
        employees: false,
        studentSubjects: false,
        editViewStudents: false,
        resetPassword: false,
        settings: false,
    },
    getters: {
        getWelcomeMessage(state){
            return state.welcomeMessage
        },
        getDashboard(state){
            return state.mainMenu
        },
        getMarkSheet(state){
            return state.markSheet
        },
        getTermReports(state){
            return state.termReports
        },
        getReports(state){
            return state.reports
        },
        getEmployees(state){
            return state.employees
        }, 
        getStudentSubjects(state){
            return state.studentSubjects
        },
        getEditViewStudents(state){
            return state.editViewStudents
        },
        getResetPassword(state){
            return state.resetPassword
        },
        getSettings(state){
            return state.settings
        }       
    },
    mutations: {
        setWelcomeMessage(state, value){
            state.welcomeMessage = value
        },
        setMainMenu(state, value){
            state.mainMenu = value
        },
        setMarkSheet(state, value){
            state.markSheet = value
        },
        setTermReports(state, value){
            state.termReports = value
        },
        setReports(state, value){
            state.reports = value
        },
        setEmployees(state, value){
            state.employees = value
        },
        setStudentSubjects(state, value){
            state.studentSubjects = value
        },
        setEditViewStudents(state, value){
            state.editViewStudents = value
        },
        setResetPassword(state, value){
            state.resetPassword = value
        },
        setSettings(state, value){
            state.settings = value
        }
    },
    actions: {
       //
    }
}    