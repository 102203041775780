<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1">
            {{ markRecord.name }}
        </td>
        <td>
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100
                class="mt-2"                
                :disabled="assesmentCourse.disable"
                :filled="assesmentCourse.disable"                
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"                
            ></v-text-field>
            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="setAttendance(assesmentCourse, true)"
                        block
                        height="20"
                        class="my-2"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                    >{{ assesmentCourse.button }}</v-btn>
                </template>
                <span>{{ assesmentCourse.tooltip }}</span>
            </v-tooltip>    
        </td>

        <td>
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100                
                class="mt-2"
                :disabled="assesmentExam.disable"
                :filled="assesmentExam.disable" 
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
            ></v-text-field>
             <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="setAttendance(assesmentExam, true)"
                        block
                        height="20"
                        class="my-2"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ assesmentExam.button }}
                    </v-btn>
                </template>
                <span>{{ assesmentExam.tooltip }}</span>
             </v-tooltip>        
        </td>

        <td>

           <v-select
                v-model="markRecord.conduct"                
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details                
                :filled="disableConduct"
                :disabled="disableConduct"
                class="mt-2" 
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>

        <!-- <td>

           <v-select
                v-model="markRecord.interest"                
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details                
                :filled="disableConduct"
                :disabled="disableConduct"
                class="mt-2" 
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>         -->
          
        <td class="subject-comments">
            <v-textarea
              v-model="comment"
              rows="2"
              maxlength="100"
              dense
              counter
              class="mb-2"
              @blur="recordChanged"              
            ></v-textarea>
             <v-btn
                text
                class="mt-2"
                style="font-size: 10px; color:green"
                height="20"
                @click="showPresetComments"
            >
                Preset Comment
            </v-btn>
        </td>

        <td>
            <v-tooltip 
                bottom                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        :color="themeColor"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                :color="themeColor"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,        
    },    
    created() {
        this.initialize(); 
    },    
    watch: {        
        
        studentRecord: {
            deep: true,
            handler (value) {
                console.log('record changed..');
                console.log(value);
                if(value.comment){                    
                    this.comment = value.comment;
                    this.markRecord.comment = value.comment;
                    console.log('saving..');
                    this.recordChanged();                    
                    //console.log(this.comment); 
                }                
            }
        },
        comment: {
            handler (value) {
                this.markRecord.comment = value;                
            }
        }
        
    },       
    data() {
        return {                               
            markRecord: {},       
            commentsConduct: [
                { id: null, comment: null},
                { id: 'A', comment: 'Excellent' },
                { id: 'B', comment: 'Very Good' },
                { id: 'C', comment: 'Good' },
                { id: 'D', comment: 'Poor' },
                { id: 'E', comment: 'Unsatisfactory' },                
            ],
            selectColumns:[
                { text: 'Conduct', model: 'conduct' }
            ],
            comment:'',
            saved: false,
            saving: false,
            error: false,
            disableConduct: false,
            presetCommentsSheet: false,
            assesmentCourse: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'course_mark',
                modelAttendance: 'course_attendance',
                test: 2,
            },
            assesmentExam: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'exam_mark',
                modelAttendance: 'exam_attendance',
                test: 1,
            },
                     
        }
    },
    computed: {
        ...mapGetters({            
            employeeId: 'auth/getEmployeeId',
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',            
            lessonSelected: 'markSheet/getLessonSelected',
            presetComments: 'enterMarks/getPresetComments',
            commentsSelected: 'enterMarks/getCommentsSelected',
            termMarksRecords: 'markSheet/getTermMarkRecords',
            test: 'markSheet/getTest',
            presetComment: 'markSheet/getPresetComment'   
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },           
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'markSheet/setUpdatedTermMarkRecord',
            setStudentSelected: 'markSheet/setStudentSelected',            
            setDisplayPresetComments: 'markSheet/setDisplayPresetComment',
            setSelectedRecordIndex: 'markSheet/setSelectedRecordIndex',
        }),
        ...mapActions({
            save: 'markSheet/save',
        }),
        initialize(){ 
            //console.log(this.studentRecord);
            this.markRecord = {...this.studentRecord};
            this.comment = this.markRecord.comment;
            this.initializeAttendance(this.assesmentCourse, this.studentRecord.course_attendance);
            this.initializeAttendance(this.assesmentExam, this.studentRecord.exam_attendance);           
            this.savedDataCheck();            
        },
        initializeAttendance(assesment, attendance){
            assesment.attendance = attendance;
            switch(attendance){
                case 1:                    
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';
                    assesment.disable = false;
                    break;
                case 2:                    
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    assesment.disable = true;
                    break;
                case 3:                    
                    assesment.button = 'NW';
                    assesment.tooltip = 'No Work';
                    assesment.disable = true;
                    break;
            }
        },                     
        async recordChanged(){
            console.log(this.markRecord);
            if(!this.recordBlankCheck()){                       
                console.log('record changed...');
                this.saving = true;
                this.saved = false;
                this.error = false;
                this.setUpdatedRecord({
                    "student_id" : this.markRecord.student_id,                    
                    "academic_term_id" : this.markRecord.academic_term_id,
                    "subject_id" : this.markRecord.subject_id,
                    "course_mark" : this.markRecord.course_mark,
                    "exam_mark" : this.markRecord.exam_mark,
                    "course_attendance" : this.markRecord.course_attendance,
                    "exam_attendance" : this.markRecord.exam_attendance,
                    "conduct" : this.markRecord.conduct,
                    // "interest": this.markRecord.interest,
                    "comment" : this.markRecord.comment,                
                    "employee_id" : this.employeeId,                   
                });

                try {
                    let response = await this.save();
                    //console.log(response);
                    if(response && (response.status === 200 || response.status === 201)){
                        this.saving = false;
                        this.saved = true;
                        this.error = false;                    
                    }
                    else{
                        this.saving = false;
                        this.error = true;
                        this.saved = false;
                    } 
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }
            }                
        },
        recordBlankCheck(){            
            let record = [];
            let blank = true;
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);
            record.push(this.markRecord.conduct);           
            // record.push(this.markRecord.interest);           
            record.push(this.markRecord.comment);            
            //console.log(record);
            record.forEach(element => {
                if(element) blank = false;
            })

            return blank;
        },
        
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);            
            this.setStudentSelected(this.markRecord.name);
        },        
        
        setDisabledFields(){
            //console.log(this.lessonSelected);
            let formLevel = this.lessonSelected.formLevel;
            if(formLevel < 6){
                this.disableConduct = false;
                this.disableApplication = false;
                this.disablePreparedness = false;
                this.disableCodedComment = true;
                this.disableCodedComment1 = true; 
            }
            else{
                this.disableConduct = true;
                this.disableApplication = true;
                this.disablePreparedness = true;
                this.disableCodedComment = false;
                this.disableCodedComment1 = false; 
            } 
        },
        showPresetComments(){ 
            console.log('show preset comments');
            let index = this.termMarksRecords.indexOf(this.studentRecord);
            //console.log(index); 
            this.setSelectedRecordIndex(index);          
            this.setDisplayPresetComments(true);
        },
        setAttendance(assesment, update = false){
            switch(assesment.attendance){
                case 1:
                    assesment.attendance = 2;
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    this.markRecord[assesment.modelAssesment] = null;
                    this.markRecord[assesment.modelAttendance] = 2
                    assesment.disable = true;
                    break;
                case 2:
                    assesment.attendance = (assesment.test === 2) ? 3 : 1;
                    if(assesment.test === 2){
                        assesment.attendance = 3;
                        assesment.button = 'NW';
                        assesment.tooltip = 'No Work';
                        this.markRecord[assesment.modelAssesment] = null;
                        this.markRecord[assesment.modelAttendance] = 3
                        assesment.disable = true;
                    }
                    else{
                        assesment.attendance = 1;
                        assesment.button = 'P';
                        assesment.tooltip = 'Present';
                        this.markRecord[assesment.modelAttendance] = 1;
                        assesment.disable = false;
                    }
                    break;
                case 3:
                    assesment.attendance = 1;
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';                    
                    this.markRecord[assesment.modelAttendance] = 1
                    assesment.disable = false;
                    break;
            }
            if(update) this.recordChanged();
        },              
    }
}
</script>

<style scoped>
    .subject-comments{
        position: relative;
    }
    .subject-comments button{
        position: absolute;
        bottom: 5px;
    }
</style>